<template>
  <div class="flex flex-wrap">
    <!-- {{ accesos }} -->

    <div v-for="acceso in accesos" :key="acceso.idopcion" class="w-full px-2 md:w-1/2 lg:w-1/3">
      <MenuItem :title="acceso.opcion" :goTo="acceso.ruta" :iconopcion="acceso.iconopcion" />
    </div>

    <!-- <div class="w-full px-2 md:w-1/2 lg:w-1/3">
      <MenuItem
        title="Establecimientos"
        goTo="/establecimientos"
        :imageSrc="require('@/assets/menus/factory.png')"
      />
    </div>

    <div class="w-full px-2 md:w-1/2 lg:w-1/3">
      <MenuItem
        title="Niños y niñas"
        goTo="/registros/ninios"
        :imageSrc="require('@/assets/menus/childs.png')"
      />
    </div>

    <div class="w-full px-2 md:w-1/2 lg:w-1/3">
      <MenuItem
        title="Gestantes"
        goTo="/registros/gestantes"
        :imageSrc="require('@/assets/menus/pregnant.png')"
      />
    </div> -->

    <!-- <div class="w-full px-2 md:w-1/2 lg:w-1/3">
      <menu-item
        title="Gráfica desnutrición"
        goTo="/graficas/desnutricion"
        :imageSrc="require('@/assets/menus/graph-down.png')"
      />
    </div>

    <div class="w-full px-2 md:w-1/2 lg:w-1/3">
      <menu-item
        title="Gráfica anemia"
        goTo="/graficas/anemia"
        :imageSrc="require('@/assets/menus/decrease.png')"
      />
    </div> -->
  </div>
</template>

<script>
import MenuItem from "@/components/MenuItem.vue";
import { computed } from "vue";

export default {
  components: {
    MenuItem
  },
  props: {
    accesos: {
      type: Array,
      default: []
    }
  }
};
</script>

<style></style>
