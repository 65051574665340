<template>
  <div class="block md:hidden">
    <MainMenuMobile
      :accesos="accesos"
      :activeKey="activeKey"
      :modulosArray="modulosArray"
      @onChangeActive="onChangeActive"
    />
  </div>
  <div class="hidden md:block">
    <MainMenuDesktop
      :accesos="accesos"
      :activeKey="activeKey"
      :modulosArray="modulosArray"
      @onChangeActive="onChangeActive"
    />
  </div>
</template>
<script>
import MainMenuDesktop from "./MainMenuDesktop.vue";
import MainMenuMobile from "./MainMenuMobile.vue";
import { generateModules } from "@/utils";
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    MainMenuMobile,
    MainMenuDesktop
  },
  setup() {
    const store = useStore();
    const modulos = store.state.user.modulos;
    const menuActive = store.state.user["menuActive"];
    const activeKey = ref(menuActive);

    const onChangeActive = (activeKey) => {
      store.dispatch("user/setMenuActive", activeKey);
    };

    const modulosArray = computed(() => {
      return generateModules(modulos);
    });

    const accesos = computed(() => {
      return {
        registro: modulos.filter((m) => m.icono === "registro"),
        estadistica: modulos.filter((m) => m.icono === "estadistica"),
        vigilancia: modulos.filter((m) => m.icono === "vigilancia"),
        proyecto: modulos.filter((m) => m.icono === "proyecto"),
        importexport: modulos.filter((m) => m.icono === "importexport"),
        nutricional: modulos.filter((m) => m.icono === "nutricional"),
        configuracion: modulos.filter((m) => m.icono === "configuracion")
      };
    });

    return {
      modulosArray,
      onChangeActive,
      accesos,
      activeKey
    };
  }
};
</script>
