<template>
  <div class="flex flex-wrap">
    <div v-for="acceso in accesos" :key="acceso.idopcion" class="w-full px-2 md:w-1/2 lg:w-1/3">
      <MenuItem :title="acceso.opcion" :goTo="acceso.ruta" :iconopcion="acceso.iconopcion" />
    </div>
    <!-- <div class="w-full px-2 md:w-1/2 lg:w-1/3">
      <menu-item
        title="Dashboard"
        goTo="/dashboard"
        :imageSrc="require('@/assets/menus/monitor.png')"
      />
    </div> -->

    <!-- <div class="w-full px-2 md:w-1/2 lg:w-1/3">
      <menu-item
        title="Indicadores"
        goTo="/indicador"
        :imageSrc="require('@/assets/menus/kpi.png')"
      />
    </div> -->
  </div>
</template>

<script>
import MenuItem from "@/components/MenuItem.vue";

export default {
  name: "MenuProyecto",
  components: {
    MenuItem
  },
  props: {
    accesos: {
      type: Array,
      default: []
    }
  }
};
</script>

<style></style>
