<template>
  <router-link
    :to="goTo"
    class="bg-white px-4 py-6 flex my-2 border rounded-lg shadow hover:border-green-500"
  >
    <div class="w-16 pr-3 m-auto">
      <img
        class="w-10 m-auto"
        :src="require('@/assets/menus/' + iconopcion + '.png')"
        :alt="title"
      />
    </div>
    <div class="flex-1 self-center">
      <h2 class="font-semibold text-gray-700 pl-3 hover:text-green-500">
        {{ title }}
      </h2>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: "Título"
    },
    goTo: {
      type: String,
      default: ""
    },
    iconopcion: {
      type: String,
      default: "default"
    }
  }
};
</script>

<style></style>
