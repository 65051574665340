<template>
  <!-- eslint-disable  -->
  <a-tabs tab-position="left" class="-ml-5" v-model:activeKey="activeKey" @change="onChangeActive">
    <a-tab-pane key="inicio">
      <template #tab>
        <span class="flex">
          <home-outlined class="text-2xl" />
          <p class="ml-2 font-semibold">Inicio</p>
        </span>
      </template>
      <MenuInicio />
    </a-tab-pane>

    <a-tab-pane v-if="modulosArray.includes('registro')" key="registro">
      <template #tab>
        <span class="flex">
          <file-text-outlined class="text-2xl" />
          <p class="ml-2 font-semibold">Registro</p>
        </span>
      </template>
      <MenuRegistro :accesos="accesos.registro" />
    </a-tab-pane>

    <a-tab-pane v-if="modulosArray.includes('estadistica')" key="estadisticas">
      <template #tab>
        <span class="flex">
          <bar-chart-outlined class="text-2xl" />
          <p class="ml-2 font-semibold">Estadística</p>
        </span>
      </template>
      <MenuEstadisticas :accesos="accesos.estadistica" />
    </a-tab-pane>

    <a-tab-pane v-if="modulosArray.includes('vigilancia')" key="vigilancia">
      <template #tab>
        <span class="flex">
          <calendar-outlined class="text-2xl" />
          <p class="ml-2 font-semibold">Vigilancia (IEC)</p>
        </span>
      </template>
      <MenuVigilancia :accesos="accesos.vigilancia" />
    </a-tab-pane>

    <a-tab-pane v-if="modulosArray.includes('proyecto')" key="proyecto">
      <template #tab>
        <span class="flex">
          <fund-projection-screen-outlined class="text-2xl" />
          <p class="ml-2 font-semibold">Dashboard</p>
        </span>
      </template>
      <MenuProyecto :accesos="accesos.proyecto" />
    </a-tab-pane>

    <a-tab-pane v-if="modulosArray.includes('importexport')" key="importexport">
      <template #tab>
        <span class="flex">
          <cloud-sync-outlined class="text-2xl" />
          <p class="ml-2 font-semibold">Import./Export.</p>
        </span>
      </template>
      <MenuImpExp :accesos="accesos.importexport" />
    </a-tab-pane>

    <a-tab-pane v-if="modulosArray.includes('nutricional')" key="nutricional">
      <template #tab>
        <span class="flex">
          <coffee-outlined class="text-2xl" />
          <p class="ml-2 font-semibold">Nutricional</p>
        </span>
      </template>
      <MenuNutricional :accesos="accesos.nutricional" />
    </a-tab-pane>

    <a-tab-pane v-if="modulosArray.includes('configuracion')" key="configuracion">
      <template #tab>
        <span class="flex">
          <setting-outlined class="text-2xl" />
          <p class="ml-2 font-semibold">Configuración</p>
        </span>
      </template>
      <MenuConfiguracion :accesos="accesos.configuracion" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import MenuInicio from "./MenuInicio.vue";
import MenuRegistro from "./MenuRegistro.vue";
import MenuEstadisticas from "./MenuEstadisticas.vue";
import MenuVigilancia from "./MenuVigilancia.vue";
import MenuProyecto from "./MenuProyecto.vue";
import MenuImpExp from "./MenuImpExp.vue";
import MenuNutricional from "./MenuNutricional.vue";
import MenuConfiguracion from "./MenuConfiguracion.vue";
// import MenuMaestros from "./MenuMaestros.vue";
import {
  HomeOutlined,
  SettingOutlined,
  FileTextOutlined,
  BarChartOutlined,
  CalendarOutlined,
  FundProjectionScreenOutlined,
  CloudSyncOutlined,
  CoffeeOutlined
} from "@ant-design/icons-vue";

export default {
  components: {
    // Menus
    MenuInicio,
    MenuRegistro,
    MenuEstadisticas,
    MenuVigilancia,
    MenuProyecto,
    MenuImpExp,
    MenuNutricional,
    MenuConfiguracion,
    // MenuMaestros,
    // Icons
    HomeOutlined,
    SettingOutlined,
    FileTextOutlined,
    BarChartOutlined,
    CalendarOutlined,
    FundProjectionScreenOutlined,
    CloudSyncOutlined,
    CoffeeOutlined
  },
  props: {
    activeKey: {
      type: String,
      default: "inicio"
    },
    modulosArray: {
      type: Array,
      default: () => []
    },
    accesos: {
      type: Object,
      default: null
    }
  },
  emits: ["onChangeActive"],
  setup(props, { emit }) {
    /* eslint-disable */
    const onChangeActive = (activeKey) => {
      emit("onChangeActive", activeKey);
    };

    return {
      onChangeActive
    };
  }
};
</script>
