<template>
  <a-page-header title="LISTA DE PAQUETES" class="pt-0 px-0 hidden">
    <template #extra>
      <a-form-item label="PERIODO" class="m-0 p-0">
        <a-select @select="onSelectYear" v-model:value="yearEvaluacion">
          <a-select-option v-for="y in years" :value="y" :key="y">
            {{ y }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </template>
  </a-page-header>

  <div v-if="isLoading" class="grid grid-cols-1 lg:grid-cols-2 gap-3">
    <div class="animate-pulse bg-purple-300 p-5 flex space-x-4">
      <div class="rounded-full bg-gray-100 h-12 w-12"></div>
      <div class="flex-1 space-y-4 py-1">
        <div class="h-4 bg-gray-100 rounded w-3/4"></div>
        <div class="space-y-2">
          <div class="h-4 bg-gray-100 rounded w-5/6"></div>
        </div>
      </div>
    </div>

    <div class="animate-pulse bg-green-200 p-5 flex space-x-4">
      <div class="rounded-full bg-gray-100 h-12 w-12"></div>
      <div class="flex-1 space-y-4 py-1">
        <div class="h-4 bg-gray-100 rounded w-3/4"></div>
        <div class="space-y-2">
          <div class="h-4 bg-gray-100 rounded w-5/6"></div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!isLoading" class="grid grid-cols-1 lg:grid-cols-2 p-4 gap-x-4 gap-y-10">
    <div v-for="grupo in grupos" :key="grupo.idgrupo">
      <CardPaquete
        goTo="/indicador/"
        :id-grupo="grupo.idgrupo"
        :bg-color="grupo.bgcolor"
        :indicadores="grupo.indicadores"
        :nombre-grupo="grupo.nombre_grupo"
        :color-flecha="grupo.colorFlecha"
      />
    </div>
  </div>

  <a-result
    v-if="!grupos && !isLoading"
    status="404"
    title="Presentamos problemas"
    sub-title="No pudimos obtener la información solicitada, por favor inténtelo más tarde"
  >
  </a-result>
</template>

<script>
import CardPaquete from "@/components/CardPaquete.vue";
import IndicadorApi from "@/api/indicador";
import { onMounted, ref } from "vue";
import { groupByPaquete } from "@/utils";
import moment from "moment";

export default {
  name: "MenuInicio",
  components: {
    CardPaquete
  },
  setup() {
    const grupos = ref(null);
    const isLoading = ref(false);
    const anios = ref([]);
    var anio = moment(new Date()).local().format("YYYY");

    var oldanios = [];
    for (let index = 0; index < 4; index++) {
      oldanios.push(anio - index);
    }

    anios.value = oldanios;

    const yearEvaluacion = ref(anios.value[1]);

    onMounted(() => {
      fetchIndicadores();
    });

    const fetchIndicadores = () => {
      isLoading.value = true;
      IndicadorApi.getWithGroup(yearEvaluacion.value)
        .then(async (res) => {
          grupos.value = await groupByPaquete(res.data);
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    const onSelectYear = () => {
      fetchIndicadores();
    };

    return {
      grupos,
      isLoading,
      years: anios,
      onSelectYear,
      yearEvaluacion
    };
  }
};
</script>

<style>
</style>
